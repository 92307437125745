.center {
    margin-left: auto;
    margin-right: auto;
  }

  .customcontainer  {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top :50px;
    transform: translate(-50%, -50%);
    height: 80%;
    /* width: 600px; */
    width:1700px;
    overflow: hidden;
    border-radius: 20px;
    background-color: #212529;
    box-shadow: 0 0 20px 8px #643eaa;
  }
  
  .content {
    position: absolute;
    text-align: justify;
    color: rgb(255, 255, 255);
    padding: 500px;
    font-family: 'Merriweather', serif;
  }

.headerFontColor{
    color: #ffffff;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}
.containerSelectStyle{
  margin-top: 20px;
    width: 100%;
    height: 100%;
    border-radius: 5%;
    padding: 10px;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    background-color: rgb(32, 117, 214);
}

tr{
  width: 1100px;
}
th{
  position:sticky;
  top: 0 ;
  background-color: black;
}

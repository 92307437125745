.backgroundColorForContentEditable{
    border-radius: 5%;
    padding: 10px;
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    /* create a border */
    border: 2px solid rgb(109, 108, 108);
}

.textCenter {
    text-align: center;
}
.modalWidth{
    min-width: 1700px !important;

    padding: 20px !important;
}


html, body{
  background-color: #343a40;
  
}
html{
  white-space: pre-line;
}

